import React from "react"

import { CartList } from "./List/CartList"
import { CartPayment } from "./Payment/CartPayment"
import { withCart } from "./withCart"
import {
  Wrapper,
  Title,
  Main,
  Desktop,
  Loading,
  ListWrapper,
  PaymentWrapper,
  ShippingMessage,
} from "./CartStyles"
import { CartEmpty } from "./Empty/CartEmpty"
import { Related } from "./Related/Related"

export const Cart = withCart(
  ({ title, count, loading, shippingNotes }): JSX.Element => (
    <Wrapper width="lg" collapse="<md">
      <Title>{title}</Title>
      {loading ? (
        <Loading>Loading...</Loading>
      ) : count > 0 ? (
        <>
          <Main>
            <ListWrapper>
              <CartList />
            </ListWrapper>
            <PaymentWrapper>
              <CartPayment layout={"cart"} />
              <ShippingMessage>{shippingNotes}</ShippingMessage>
            </PaymentWrapper>
          </Main>
          <Desktop>
            <Related showAddToCart />
          </Desktop>
        </>
      ) : (
        <CartEmpty />
      )}
    </Wrapper>
  ),
)
