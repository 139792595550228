import tw from "twin.macro"

export const Wrapper = tw.div`
  flex flex-col items-end
`

export const Title = tw.h2`
  w-full text-left block font-bold text-14 leading-1.71 uppercase tracking-10 pb-1-6
`

export const LineItems = tw.ul`
  mb-1-6 md:mb-3-2 w-full border-t border-b border-navy border-opacity-20
`

export const Total = tw.div`
  text-14 leading-1.71 font-semibold uppercase
`

export const Row = tw.div`
  flex flex-row justify-between w-full items-center
`

export const Continue = tw.div`
  invisible md:visible
`
