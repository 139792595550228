import React from "react"
import { Link } from "gatsby"

import { CartItem } from "../Item/CartItem"
import { withCartList } from "./withCartList"
import {
  Wrapper,
  Title,
  LineItems,
  Total,
  Row,
  Continue,
} from "./CartListStyles"
import { UnderlinedButton } from "../../Styled/Button"

export const CartList = withCartList(
  ({
    lineItems,
    additionalLineItemsListTitle,
    additionalLineItemsTotalText,
    count,
    continueLink,
  }): JSX.Element => (
    <Wrapper>
      <Title>{additionalLineItemsListTitle}</Title>
      <LineItems>
        {lineItems?.map((item, idx) => (
          <CartItem
            key={idx + 'cart-item-cart-list'}
            item={item}
            layout="cart"
            lineItems={lineItems}
          />
        ))}
      </LineItems>
      <Row>
        <Continue>
          <UnderlinedButton size="tertiary" as={Link} to={continueLink.url}>
            <span>{continueLink.title}</span>
          </UnderlinedButton>
        </Continue>
        <Total>
          {additionalLineItemsTotalText}:&nbsp;{count}
        </Total>
      </Row>
    </Wrapper>
  ),
)
