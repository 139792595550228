import React from "react"

import { withRelated } from "./withRelated"
import { Wrapper } from "./RelatedStyles"
import { ProductsCarousel } from "../../Sections/ProductsCarousel/ProductsCarousel"

export const Related = withRelated(
  ({ title, collection, items, showAddToCart }): JSX.Element =>
    items?.length > 0 || collection?.shopify?.shopifyHandle.length > 0 ? (
      <Wrapper>
        <ProductsCarousel
          products={items}
          collection={collection}
          title={title}
          layout={"normal"}
          id={"related"}
          showAddToCart={showAddToCart}
        />
      </Wrapper>
    ) : null,
)
