import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`py-4 md:py-8`}
`

export const Title = tw.h1`
  text-28 leading-1.28 tracking-10 md:pb-2-8 font-bold uppercase text-center md:text-left px-2-4
`

export const Main = tw.div`
  grid grid-cols-1 md:grid-cols-2 mlg:grid-cols-5 md:gap-x-1 mlg:gap-x-4 mb-0-8
`

export const Mobile = tw.div`
  md:hidden
`

export const Desktop = tw.div`
  hidden md:block
`

export const Loading = tw.p`
  text-center text-20
`

export const ListWrapper = tw.div`
  px-2-4 md:px-3-2 py-2-4 md:col-span-1 mlg:col-span-3
`

export const PaymentWrapper = tw.div`
  md:col-span-1 mlg:col-span-2 
`

export const ShippingMessage = tw.p`
  pt-4 md:pt-0 px-2-4 text-grey text-12 leading-1.67 text-center
`
