import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withRelated = Component => ({
  name = "Related",
  showAddToCart = false,
}) => {
  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_RELATED {
      cart: sanityPageCart {
        relatedTitle
        relatedProducts: _rawRelatedProducts(resolveReferences: { maxDepth: 4 })
        relatedCollection {
          shopify {
            shopifyHandle
          }
        }
      }
    }
  `)
  const { relatedProducts, relatedCollection, relatedTitle } = cart || {}

  Component.displayName = name
  return (
    <Component
      title={relatedTitle}
      items={relatedProducts}
      collection={relatedCollection}
      showAddToCart={showAddToCart}
    />
  )
}
