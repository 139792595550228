import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCheckoutContext } from "../../../hooks/useCheckout"
import { useRoutes } from "../../../hooks/useRoutes"

export const withCartList = Component => ({ name = "CartList" }: any) => {
  const { checkout, count } = useCheckoutContext()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_LIST {
      cart: sanityPageCart {
        continueShopping: _rawContinueShopping(
          resolveReferences: { maxDepth: 2 }
        )
        additionalLineItemsListTitle
        additionalLineItemsTotalText
      }
    }
  `)

  const {
    continueShopping,
    additionalLineItemsListTitle,
    additionalLineItemsTotalText,
  } = cart || {}

  const { linkResolver } = useRoutes()

  const continueLink = linkResolver(continueShopping)

  Component.displayName = name
  return (
    <Component
      lineItems={checkout?.lineItems}
      count={count}
      continueLink={continueLink}
      additionalLineItemsListTitle={additionalLineItemsListTitle}
      additionalLineItemsTotalText={additionalLineItemsTotalText}
    />
  )
}
