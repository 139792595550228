import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const Wrapper = tw.div`
  flex flex-col bg-grey-light
`

export const FreeShippingCallToAction = tw.p`
  text-center mb-1-6 text-12 leading-1.67 uppercase tracking-5
`

export const CheckoutButton = styled(StyledButton)`
  ${tw`mb-1-6`}
`
