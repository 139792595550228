import React from "react"
import { useCheckoutContext } from "../../hooks/useCheckout"

export const withCart = Component => ({ name = "Cart", page }) => {
  const { title, shippingNotes } = page || {}
  const { count, initializedLoading: loading } = useCheckoutContext()

  Component.displayName = name
  return (
    <Component
      title={title}
      count={count}
      loading={loading}
      shippingNotes={shippingNotes}
    />
  )
}
