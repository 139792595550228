import React from "react"

import { withCartPayment } from "./withCartPayment"
import { Wrapper, Title, Main } from "./PaymentStyles"
import { DiscountCode } from "./DiscountCode/DiscountCode"
import { Summary } from "./Summary/Summary"
import { Checkout } from "./Checkout/Checkout"
import { FreeGiftPicker } from "../../FreeGiftPicker/FreeGiftPicker"

export const CartPayment = withCartPayment(({ layout, freeGiftProducts }) => (
  <Wrapper layout={layout}>
    <Title>Payment</Title>
    <Main>
      <DiscountCode />
      <Summary />
      {freeGiftProducts.length > 0 && (
        <FreeGiftPicker />
      )}
      <Checkout />
    </Main>
  </Wrapper>
))
