import React from "react"
import { graphql } from "gatsby"

import { Cart as Page } from "../components/Cart/Cart"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageCart {
      title
      shippingNotes
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("cart_page")

  return <Page {...props} {...data} />
}
export default Component
