import React from "react"

import { withCheckout } from "./withCheckout"
import {
  Wrapper,
  CheckoutButton,
  FreeShippingCallToAction,
} from "./CheckoutStyles"

export const Checkout = withCheckout(
  ({
    loading,
    handleCheckout,
    freeShippingAmount,
    freeShippingCallToAction,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
  }): JSX.Element => (
    <>
      <Wrapper>
        <CheckoutButton size={"primary"} onClick={handleCheckout} wide>
          {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
        </CheckoutButton>
        {freeShippingAmount > 0 ? (
          <FreeShippingCallToAction>
            {freeShippingCallToAction}
          </FreeShippingCallToAction>
        ) : null}
      </Wrapper>
    </>
  ),
)
