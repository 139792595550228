import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`flex flex-col`}
  ${({ layout }) => {
    if (layout === "cart") return tw`bg-grey-light px-2-4 md:px-3-2 py-2-4`
  }}
`

export const Title = tw.h2`
  w-full text-left block font-bold text-14 leading-1.71 uppercase tracking-10 mb-2-1
`

export const Main = tw.div`
  border-t border-navy border-opacity-20 flex flex-col
`
